<template>
  <div></div>
</template>

<script>
export default { 
  async created() {
    await this.createEasemob();
    this.init();
  },
  methods: {
    init() {
      window.easemobim = window.easemobim || {};
      window.easemobim.config = {
        configId: "8cb27232-dd94-415a-899b-7924e443154e",
      };
      window.easemobim.bind();
        setTimeout(() => {
        this.$LoadingClose()
        }, 1000);
    },
    createEasemob() {
      return new Promise((resolve) => {
        const mapScript = document.createElement("script");
        mapScript.type = "text/javascript";
        mapScript.src =
          "//101973.kefu.easemob.com/webim/easemob.js?configId=8cb27232-dd94-415a-899b-7924e443154e";
        document.body.appendChild(mapScript);
        mapScript.onload = () => {
          resolve();
        };
      });
    },
  },
};
</script>

<style></style>
